import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { useDetailProps } from '../../detailProvider';
import arrow from '../../images/arrow_down.svg';
import { getValue } from '../../utils';

const FundsSection = ({funds}) => {

  const detailProps = useDetailProps();

  return (
    <div id='funds'>
        <div className="holder pb-36 pt-20">
            <div className="flex justify-between pb-20 text-100 sm:flex-wrap sm:text-3xl">
                <div className="uppercase font-bold">Nanos</div>
                <div className="uppercase font-bold">Trading</div>
                <div className="uppercase font-bold">Funds</div>
            </div>
            <div className='border-2 border-dashed border-black border-raduis-l  p-8 m-5'>
                <Link to="/funds/0">
                    <div className='text-sm border-2 border-black rounded-3xl px-2 w-max mb-4'>Funding Open</div>
                    <div className='flex justify-between mb-6 md:flex-wrap'>
                        <div className='max-w-2xl'>
                            <div className="text-normal uppercase text-5xl font-bold mb-6">
                                Liquidity Delta Strategy
                            </div>
                            <div className="text-normal">
                                Fund utilizes artificial intelligence and machine learning algorithms to make informed investment decisions in the cryptocurrency market.
                            </div>
                        </div>
                        <div className='grid grid-cols-2 md:mt-5'>
                            <div className='flex flex-col'>
                                <div className="text-normal" style={{display: "flex", alignItems: "stretch",flex: 1}}>Liquidity:</div>
                                <div className="text-normal">Launch:</div>
                                <div className="text-normal">Minimum Investment:</div>
                                <div className="text-normal">Investor Type:</div>
                                <div className="text-normal">Subscriptions:</div>
                                <div className="text-normal">Redemptions:</div>
                            </div>
                            <div className='flex flex-col'>
                                <div className="text-normal font-bold" style={{ maxWidth: "390px", display: "flex",alignItems: "stretch"}}> Daily and assets: ADA, AGIX, APT, ATOM, AVAX, BNB, BTT(BTTC), BTC, BLUR, DOT, DYDX, ETH, FET, FIL, GRT, GMX, GRT, HBAR, IMX, LDO, LINK, MATIC, NEAR, NFP, OCEAN, OP, RLC, RNDR, ROSE, SOL, STG, SUI"</div>
                                <div className="text-normal font-bold">January 2024</div>
                                <div className="text-normal font-bold">$100,000</div>
                                <div className="text-normal font-bold">Accredited</div>
                                <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                                <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                            </div>
                        </div>
                    </div>
                    <div className='ml-auto w-max'>
                        <div className='flex text-base font-bold gap-4'>ABOUT THE FUND<img className='h-3.5 my-auto' src={arrow} alt="" /></div>    
                    </div>
                </Link>
            </div>
            {/* <div className='border-2 border-dashed border-black border-raduis-xl py-8 px-5 m-5'>
                <Link to="/funds/1" >
                    <div className='text-sm text-yellow-1 bg-black border-2 border-black rounded-3xl px-2 w-max mb-4'>Funding Closed</div>
                    <div className='flex justify-between mb-6 md:flex-wrap'>
                        <div className='max-w-2xl'>
                            <div className="text-normal uppercase text-5xl font-bold mb-6 uppercase">
                                Levels AI Algorithm Trading Crypto Fund
                            </div>
                            <div className="text-normal">
                                Fund utilizes artificial intelligence and machine learning algorithms to make informed investment decisions in the cryptocurrency market.
                            </div>
                        </div>
                        <div className='grid grid-cols-2 md:mt-5'>
                            <div className='flex flex-col'>
                                <div className="text-normal">Liquidity:</div>
                                <div className="text-normal">Launch:</div>
                                <div className="text-normal">Minimum Investment:</div>
                                <div className="text-normal">Investor Type:</div>
                                <div className="text-normal">Subscriptions:</div>
                                <div className="text-normal">Redemptions:</div>
                            </div>
                            <div className='flex flex-col'>
                                <div className="text-normal font-bold">Daily and assets: BTC/USDT/ETH/XRP/USDC</div>
                                <div className="text-normal font-bold">January 2024</div>
                                <div className="text-normal font-bold">$100,000</div>
                                <div className="text-normal font-bold">Accredited & Non Accredited</div>
                                <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                                <div className="text-normal font-bold">Monthly, Upon One Day’s Notice</div>
                            </div>
                        </div>
                    </div>
                    <div className='ml-auto w-max'>
                        <div className='flex text-base font-bold gap-4'>ABOUT THE FUND<img className='h-3.5 my-auto' src={arrow} alt="" /></div>
                    </div>
                </Link>
            </div> */}

            {/* 
            <div className='border-b-2 border-dashed border-black-20 py-8 px-5'>
                <div className='text-sm text-yellow-1 bg-black border-2 border-black rounded-3xl px-2 w-max mb-4'>Funding Closed</div>
                <div className='flex justify-between mb-6 md:flex-wrap'>
                    <div className='max-w-2xl'>
                        <div className="text-normal uppercase text-5xl font-bold mb-6">
                            Levels AI Algorithm Trading Crypto Fund
                        </div>
                        <div className="text-normal">
                            Fund utilizes artificial intelligence and machine learning algorithms to make informed investment decisions in the cryptocurrency market.
                        </div>
                    </div>
                    <div className='grid grid-cols-2 md:mt-5'>
                        <div className='flex flex-col'>
                            <div className="text-normal">Liquidity:</div>
                            <div className="text-normal">Launch:</div>
                            <div className="text-normal">Minimum Investment:</div>
                            <div className="text-normal">Investor Type:</div>
                            <div className="text-normal">Subscriptions:</div>
                            <div className="text-normal">Redemptions:</div>
                        </div>
                        <div className='flex flex-col'>
                            <div className="text-normal font-bold">Daily and assets: BTC/USDT/ETH</div>
                            <div className="text-normal font-bold">January 2024</div>
                            <div className="text-normal font-bold">$100,000</div>
                            <div className="text-normal font-bold">Accredited</div>
                            <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                            <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                        </div>
                    </div>
                </div>
                <div className='ml-auto w-max'>
                <Link className='flex text-base font-bold gap-4' to="/funds/3">ABOUT THE FUND<img className='h-3.5 my-auto' src={arrow} alt="" /></Link>
                </div>
            </div>
            <div className='border-b-2 border-dashed border-black-20 py-8 px-5'>
                <div className='text-sm text-yellow-1 bg-black border-2 border-black rounded-3xl px-2 w-max mb-4'>Funding Closed</div>
                <div className='flex justify-between mb-6 md:flex-wrap'>
                    <div className='max-w-2xl'>
                        <div className="text-normal uppercase text-5xl font-bold mb-6">
                            Levels AI Algorithm Trading Crypto Fund
                        </div>
                        <div className="text-normal">
                            Fund utilizes artificial intelligence and machine learning algorithms to make informed investment decisions in the cryptocurrency market.
                        </div>
                    </div>
                    <div className='grid grid-cols-2 md:mt-5'>
                        <div className='flex flex-col'>
                            <div className="text-normal">Liquidity:</div>
                            <div className="text-normal">Launch:</div>
                            <div className="text-normal">Minimum Investment:</div>
                            <div className="text-normal">Investor Type:</div>
                            <div className="text-normal">Subscriptions:</div>
                            <div className="text-normal">Redemptions:</div>
                        </div>
                        <div className='flex flex-col'>
                            <div className="text-normal font-bold">Daily and assets: BTC/USDT/ETH</div>
                            <div className="text-normal font-bold">January 2024</div>
                            <div className="text-normal font-bold">$100,000</div>
                            <div className="text-normal font-bold">Accredited</div>
                            <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                            <div className="text-normal font-bold">Daily, Upon One Day’s Notice</div>
                        </div>
                    </div>
                </div>
                <div className='ml-auto w-max'>
                <Link className='flex text-base font-bold gap-4' to="/funds/4">ABOUT THE FUND<img className='h-3.5 my-auto' src={arrow} alt="" /></Link>
                </div>
            </div> */}

  
            {/* <div className='text-xl py-4 px-4 w-max mx-auto rounded-full bg-black text-yellow-1 cursor-pointer mt-10'>
            <Link to="/funds">EXPLORE ALL FUNDS</Link>
            </div> */}
        </div>
    </div>
  )
}

export default FundsSection